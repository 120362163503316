import { faTrash, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Project, Shipyard } from "types";
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { listShipyards } from "Services/shipyards";
import Modal from "Components/Modal/Modal";

interface DataTableProps {
    title: string;
    lastPage:number;
    currentPage: number;
    from: number;
    to: number;
    total: number;
    selectedpaginationItem: number;
    paginationItems: number[]
    headers: string[]
    data: any
    onDeleteData: (id: number) => void;
    onSearch: (id: string) => void;
    onPaginate: (page: number) => void;
    onChangePerPage: (page: number) => void;
    onShow?:(e:any)=>void
}

const DataTable = (props: DataTableProps) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [shownProject,setShownProject]=useState<any>()
    const handlePreviousClick = () => {
        if (props.currentPage > 1) {
            const previousPage = props.currentPage - 1;
            props.onPaginate(previousPage);
        }
    };
    const [shipyards,setShipyards]=useState<any>([])
    const getAllShipyard=async()=>{
      const shipyardata:any=await listShipyards()
      console.log("shipyards",shipyardata)
      setShipyards(shipyardata.data)
    }
    useEffect(()=>{
        getAllShipyard()
    },[])
    const handleNextClick = () => {
        if (props.currentPage < props.lastPage) {
            const nextPage = props.currentPage + 1;
            props.onPaginate(nextPage);
        }
    };
    const handlePageClick = (page:number) => {
        if (page) {
            props.onPaginate(page);
        }
    };
    const isProject = (obj: Project | Shipyard): obj is Project => {
        return true
    };
    const [showProject,setShowProject]=useState(false)
    const handleShowProject=(selectedProject:any)=>{
        setShowProject(!showProject)
        const gettedProj:any=props?.data?.filter((project:any)=>project.id==selectedProject.id)[0]
        setShownProject(gettedProj)
    }
    useEffect(()=>{
        console.log("showProject",shownProject)
    },[showProject])
    const navigate = useNavigate();
    const onSearchItem = (item: string) => {
        setSearchTerm(item);
        props.onSearch(item);
    };
    const handleDeleteClick = (id: number) => {
        props.onDeleteData(id);
    };
    const handleEditClick = (id: number) => {
        // Assuming you have an 'id' property on your Project or Shipyard
        navigate(`./edit/${id}`);
    };
    const pages = [];
    for (let i = 1; i <= props.lastPage; i++) {
        pages.push(
            <li key={i}  onClick={() => handlePageClick(i)} className={`page-item ${props.currentPage === i ? 'active' : ''}`}>
                <a className="page-link" href="javascript:void(0)">{i}</a>
            </li>
        );
    }
    return (
        <div className="card shadow">
            <div className="card-header py-3">
                <p className="text-primary m-0 fw-bold">{props.title}</p>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 text-nowrap">
                        <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable"><label className="form-label">Show&nbsp;
                        <select 
    className="d-inline-block form-select form-select-sm" 
    value={props.selectedpaginationItem} 
  onChange={(e)=>{ props.onChangePerPage(parseInt(e.target.value));}}
>
    {props.paginationItems.map((pagination: number) => (
        <option 
            key={pagination} 
            value={pagination}
        >
            {pagination}
        </option>
    ))}
</select>

                        &nbsp;</label></div>
                    </div>
                    <div className="col-md-6">
                        <div className="text-md-end dataTables_filter" id="dataTable_filter"><label className="form-label"><input type="search" className="form-control form-control-sm" aria-controls="dataTable" placeholder="Search" value={searchTerm}
                            onChange={(e) => onSearchItem(e.target.value)} /></label></div>
                    </div>
                </div>
                <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                    <table className="table my-0" id="dataTable">
                        <thead>
                            <tr>
                                {props.headers.map((header) => <th>{header}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {props.data && props.data.map((project: Project | Shipyard) => (
                                <tr>
                                    <td>
                                        {project.name}
                                    </td>

                                    {props.title != "Shipyards" && isProject(project) && (
                                        <>
                                            <td>{project.customer_name}</td>
                                            <td>{project.number}</td>
                                            <td>{project.start_date}</td>
                                            <td>{project.delivery_date}</td>
                                        </>
                                    )}


                                    <td>{props.title != "Shipyards" && isProject(project) && (
                                        <span className="badge bg-primary" style={{ margin: 2, cursor: "pointer" }} onClick={()=>handleShowProject(project)}>
                                            <FontAwesomeIcon icon={faEye} />
                                        </span>)}  <span className="badge bg-primary" onClick={() => handleEditClick(project.id as number)} style={{ margin: 2, cursor: "pointer" }}>
                                            <FontAwesomeIcon icon={faEdit} /></span>
                                        <span className="badge bg-primary" onClick={() => handleDeleteClick(project.id as number)} style={{ margin: 2, cursor: "pointer" }}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </span>


                                    </td>


                                </tr>

                            ))

                            }
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    <div className="col-md-6 align-self-center">
                        <p id="dataTable_info" className="dataTables_info" role="status" aria-live="polite">Showing {props.from} to {props.to} of {props.total}</p>
                    </div>
                    <div className="col-md-6">
                        <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                            <ul className="pagination">
                                <li className={`page-item ${props.currentPage === 1 ? 'disabled' : ''}`}>
                                    <a className="page-link" aria-label="Previous" href="javascript:void(0)" onClick={handlePreviousClick}>«</a>
                                </li>
                                {pages}
                                <li className={`page-item ${props.currentPage === props.lastPage ? 'disabled' : ''}`}>
                                    <a className="page-link" aria-label="Next" href="javascript:void(0)" onClick={handleNextClick}>»</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            {showProject &&<Modal isShow={true}
        dataModal={shownProject}
          getDataModal={()=>{}}
          onClose={() => {setShowProject(false) ;}}
          onSubmit={async () => {
            navigate("/projects/new-project", { state: shownProject });
          }}
          onAddShipyard={async () => {
            navigate("/shipyards/new-shipyard", { state: shownProject });
          }}
           title="Project"
          subtitle="shipyard"
          options={shipyards.data}
        />
}
        </div>
    )
}
export default DataTable;