import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faFileAlt, faRocket,faHome,faShip, faSignOutAlt, faUser,faCogs,faList, faDonate, faEnvelope, faExclamationTriangle, faBars } from "@fortawesome/free-solid-svg-icons";
import './style.css';
import { useLocation,useNavigate } from "react-router-dom";

interface HeaderProps {}
const Header = (props: HeaderProps) => {
  const navigate=useNavigate()
  return (
    <>
    <nav className="top">
        <label className="logo"> <img src="/logo.png" alt="Logo" /> </label>
        <ul className="topUl">
            <li><a href="#">Accueil</a></li>
            <li><a href="#" onClick={async () => {
            navigate("/projects", { state: null });
          }} >Projects</a></li>
            <li><a href="/shipyards">Shipyard</a></li>
            <li><a href="#">notifications</a></li>
        </ul>
    </nav>
    <nav className="bottom">
        <ul className="bottomUl">
            <li><a href="#"><FontAwesomeIcon icon={faHome} className="fa-home me-2 text-gray-400" /></a></li>
            <li><a href="/projects"><FontAwesomeIcon icon={faRocket} className="fa-sm me-2 text-gray-400" /></a></li>
            <li><a href="/shipyards"><FontAwesomeIcon icon={faShip } className="fa-sm me-2 text-gray-400" /></a></li>
            <li><a href="#"><FontAwesomeIcon icon={faBell } className="fa-sm me-2 text-gray-400" /></a></li>
        </ul>
    </nav>
    </>
  );
};
export default Header;
