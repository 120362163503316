import DataTable from "../../Components/DataTable/DataTable";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import NavBar from "../../Components/NavBar/NavBar";
import Menu from "../../Components/Menu/Menu";
import React, { useEffect, useState } from "react";
import { listShipyards,deleteShipyard } from "Services/shipyards";
import { Shipyard } from "types";
function Shipyards() {
  const [shipyards,setShipyards]=useState<Shipyard[]>()
  const [searchTerm, setSearchTerm] = useState("");
  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedpaginationItem, setSelectedpaginationItem] = useState<number>(10);

  const [formTable, setFrom] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const getAllShipyards = async (data = {}) => {
    try {
      const projects = await listShipyards(data);
      console.log("Request projects:", projects);
      setLastPage(projects.data.last_page);
      setCurrentPage(projects.data.current_page);
      setShipyards(projects.data.data);
      setFrom(projects.data.from);
      setTo(projects.data.to);
      setTotal(projects.data.total);
      setSelectedpaginationItem(projects.data.per_page);

    } catch (error) {
      console.error("Error in getAllShipyards:", error);
    }
  };
  
  const DeleteData = async (id: number) => {
    try {
      const req = await deleteShipyard(id);
      getAllShipyards();
      console.log("Request result:", req);
    } catch (error) {
      console.error("Error in DeleteData:", error);
    }
  };
  const onPaginate = async (page: number) => {
    try {
      let data = { page: page,perpage:selectedpaginationItem };
      getAllShipyards(data  );
    } catch (error) {
      console.error("Error in DeleteData:", error);
    }
  };
  const onChangePerPage = async (page: number) => {
    try {
      let data = { perpage: page };
      getAllShipyards(data);
    } catch (error) {
      console.error("Error in DeleteData:", error);
    }
  };
  const SearchData = async (searchText: string) => {
   
    try {
      let data = { search: searchText };
      console.log(data);
      getAllShipyards(data);
      console.log("Search result:", searchText);
    } catch (error) {
      console.error("Error in SearchData:", error);
    }
  };
  
  useEffect(() => {
    let data = { perpage: selectedpaginationItem };
    getAllShipyards(data);
  }, []);
  
  return (
    <React.Fragment >
       <Header />
        <div className="container-fluid">
          <div className="row">
          {/*<div className="col-md-2 ps-0">

      <Menu title="Slim" items={[{name:"Dashboard"},{name:"Projects"},{name:"Shipyards"},{name:"Profile"},{name:"Register"}]}/>
  </div>*/}
      {/*<div className="col-md-10">*/}
      <div className="col-md-12">
    <div className="d-flex flex-column" id="content-wrapper">
        <div id="content">
            <div className="container-fluid">
                <NavBar title="Shipyards" />
                <DataTable onPaginate={onPaginate} onChangePerPage={onChangePerPage}  onSearch={SearchData} selectedpaginationItem={selectedpaginationItem} paginationItems={[10,15,20,30]} onDeleteData={DeleteData} title="Shipyards" headers={["Shipyard Name","Actions"]} data={shipyards} lastPage={lastPage} currentPage={currentPage} from={formTable} to={to} total={total} />
            </div>
        </div>
          <Footer title="Copyright © Brand 2024" />
    </div><a className="border rounded d-inline scroll-to-top" href="#page-top"><i className="fas fa-angle-up"></i></a>
</div>
</div>
</div>
</React.Fragment>
  )

}

export default Shipyards;
