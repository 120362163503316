import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalTask from "Components/Modal/ModalTask";
import { listGroups } from "Services/groups";
import {  useEffect, useState } from "react";
import {  Group, Task } from "types";

interface ProjectCardProps{
    title:string,
    tasks:Task[]
    editable?:boolean
    getAddedTask:(e:any)=>void;
    getTasksAfterUpdate:(e:any)=>void,
    projectData:any

}


const ProjectCard=(props:ProjectCardProps ) =>{
const [checkedTaskName,setCheckedTaskName]=useState<boolean>(false)
const [selectedItem,setSelectedItem]=useState<any>()
const [selectedItems,setSelectedItems]=useState<any>([])

 const [showModalTask,setShowModalTask]=useState(false)
 const [showEditModalTask,setShowEditModalTask]=useState(false)
const [addedTask,setAddedTask]=useState<any>();
console.log("props",props)
const [tasks,setTasks]=useState<Task[]>([])
const [group,setGroup]=useState<any>(props)
const [openEditModal,setOpenEditModal]=useState<boolean>(false)
const [groups,setGroups]=useState<Group[]>([])
const [updatedTasks,setUpdatedTask]=useState<any>()
useEffect(()=>{
  setGroup({title:props.title,tasks:props.tasks})
  setTasks(props.tasks)
  console.log("propsTas",tasks)
},[props])
  const getAddedTaskDetailsData=(addedTask:any)=>{
    setAddedTask({...addedTask})
    setTasks(prevState=>prevState.map(task => {
      if (task.id == addedTask?.taskname_id) {
        console.log("hello task")
        return {
          ...task,
          isUpdated: true
        };
      }
      return {...task};
    }))
    group.name=props.title;
    group.tasks=tasks.map(task => {
      if (task.id == addedTask?.taskname_id) {
        task.article=addedTask
        console.log("hello task")
        return {
          ...task,
          isUpdated: true
        };
      }
      return {...task};
    });
    console.log("groupTasks",group)
    setGroup(group)
    console.log("hellouser",{...selectedItem,article:addedTask})
    setSelectedItem({...selectedItem,article:addedTask})
    props.getTasksAfterUpdate(group)


  }
  useEffect(()=>{
    console.log("groups------",group)
  },[group])

useEffect(()=>{
  console.log("selectedItem",selectedItem)
},[selectedItem])


  useEffect(()=>{
    console.log("taskAfterUpdate",group)
  },[group])
  useEffect(()=>{
    console.log("hello")
    props.getAddedTask(addedTask)
  },[addedTask])
  useEffect(()=>{
    console.log("propsCard",props)
    console.log("addedTaskCard",addedTask)

  },[addedTask])
  useEffect(()=>{
    console.log("selectedItems",selectedItems)
  },[selectedItems])
  return (
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        <h6 className="text-primary fw-bold m-0">{props.title}</h6>
      </div>
      <div className="card-body">
        {group?.tasks?.map((item:any, index:number) => (
          <>
           {item.isUpdated==true?
          <h4  className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px' }}>
         <input type="checkbox" key={index} checked={item.isUpdated==true} onChange={(e:any)=>{setSelectedItem({...item,isUpdated:true}); setSelectedItems([...selectedItems,item]);setCheckedTaskName(e.target.checked);setShowModalTask(true);setAddedTask(null) }} />&nbsp; {item.name}
          <span className="badge bg-primary" onClick={()=>setOpenEditModal(true)} style={{ margin: 2, cursor: "pointer" }}>
<FontAwesomeIcon onClick={()=>{setSelectedItem({...item});setShowEditModalTask(true)}} icon={faEdit}/>
</span>
            </h4>:
       <h4  className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px' }}>
       <input type="checkbox" key={index} checked={item.isUpdated==true} onChange={(e:any)=>{setSelectedItem({...item,isUpdated:true});setCheckedTaskName(e.target.checked);setSelectedItems([...selectedItems,item]);setShowModalTask(true);setAddedTask(null) }} />&nbsp; {item.name}
          </h4>
}
          </>
        ))}

            {showModalTask && checkedTaskName &&<ModalTask isEdit={false} getAddedTaskData={getAddedTaskDetailsData} task={selectedItem} onSubmit={()=>{setShowModalTask(false);}} projectData={props.projectData} onCancel={()=>{setShowModalTask(false);setCheckedTaskName(false)}}/>}
            {openEditModal && checkedTaskName &&<ModalTask isEdit={true} article={selectedItem.article} getAddedTaskData={getAddedTaskDetailsData} task={selectedItem} projectData={props.projectData} onSubmit={()=>{setOpenEditModal(false);}} onCancel={()=>{setOpenEditModal(false);setCheckedTaskName(false)}}/>}

      </div>

    </div>
  );
}
export default ProjectCard
