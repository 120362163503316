import React, { useEffect,useState,ChangeEvent  } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from "../../Components/Card/Card";
import Card2 from "../../Components/Card/Card2";
import Card3 from "../../Components/Card/Card3";
import Menu from "../../Components/Menu/Menu";
import Header from "../../Components/Header/Header";
import {  updateShipyard } from "Services/shipyards";
import { useNavigate  } from 'react-router-dom';
import { getShipyardById } from "Services/shipyards";
import { useParams } from 'react-router-dom';
const EditShipyard = () => {
  const { id } = useParams();
  const numericId: number | undefined = id ? +id : undefined;
  const getShipyard=async()=>{
    if (numericId !== undefined) {
    const Shipyard=await getShipyardById(numericId);
    console.log(Shipyard.data)
    if(Shipyard.data && Shipyard.data.photo){
      setImageSrc(`https://testi.sfaxcharityerpapp.com/${Shipyard.data.photo}`)
    }
    if(Shipyard.data ){
      setshipyardName(Shipyard.data.name)
    }
    if(Shipyard.data.shipyard_staff ){
     
      const updatedArray = Shipyard.data.shipyard_staff.map((item:any,index:number) => {
        const {  cost, sell, number, staff_id } = item;
        const { name } = item.staff;
        return {
          id:index,
          name,
          cost,
          sell,
          number,
          staff_id,
        };
      });
      setShipyardStuffData(updatedArray)
      setShipyardStuffcartData(updatedArray)
    }
  }}
  useEffect(()=>{
    getShipyard()
  },[])
  const [shipyardStuffcartData, setShipyardStuffcartData] = useState<Array<{ id: number; staff_id:number| undefined; name: string;number: number | undefined; cost: number; sell: number }>>([]);

  const [shipyardStuffData, setShipyardStuffData] = useState<Array<{ id: number;staff_id:number| undefined; name: string; cost: number; sell: number }>>([]);

  const handleStuffDataChange = (newStuffData: Array<{ id: number;staff_id:number| undefined; name: string; cost: number; sell: number }>) => {
    setShipyardStuffData(newStuffData);
  };
  const [shipyardName, setshipyardName] = useState('');
  const navigate = useNavigate();

  const handleAddShipyard = async () => {
    console.log("1111",shipyardStuffData);
    if (numericId !== undefined) {
    const formData = new FormData();
    formData.append('name', shipyardName);
    formData.append('photo', imageSrc as File);
    formData.append('staffs', JSON.stringify(shipyardStuffData));

    try {
      const addShipyardRes = await updateShipyard(numericId,formData);

      // Check if the status code is either 200 or 201
      if (addShipyardRes.status === 200 || addShipyardRes.status === 201) {
        // Display success toast
        toast.success('Shipyard successfully edited!', {
          position: 'top-center', // Corrected position string
          autoClose: 2000, // Adjust the duration of the toast
        });
        setTimeout(() => {
          navigate('/shipyards');
        }, 2000);
        // Redirect to /shipyard after a brief delay
     
      }
    } catch (error) {
      console.error("Error editing shipyard:", error);
    }
  }};

  
  const [imageSrc, setImageSrc] = useState<File | string>('/not_found.jpg');

   const handlePhotoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      setImageSrc(selectedFile);
    }
  };
  return (
    <>
     <Header />
     <ToastContainer />
    <div className="container-fluid ">
      <div className="row" > 
      
        <div className="col-md-12">
          <h3 className="text-dark mb-4">Edit Shipyard </h3>
          <div className="row mb-3">
            <div className="col-lg-4">
              <div className="card mb-3">
              <div className="card-body text-center shadow">
      <img
        className="rounded-circle mb-3 mt-4"
        src={imageSrc instanceof File ? URL.createObjectURL(imageSrc) : imageSrc}
        alt="User's profile"
        width="121"
        height="117"
      />
      <div className="mb-3">
        <input
          type="file"
          accept="image/*"
          id="photoInput"
          style={{ display: 'none' }}
          onChange={handlePhotoChange}
        />
        <label htmlFor="photoInput" className="btn btn-primary btn-sm">
          Change Photo
        </label>
      </div>
    </div>
              </div>
              <Card
                title="Stuff"
                children={[
                  "Mechanics",
                  "Sales Tracking",
                  "Customer Databases",
                  "Payout Details",
                  "Account setup",
                ]}
                data ={shipyardStuffcartData}
                onStuffDataChange={handleStuffDataChange}
              />
            </div>
            <div className="col-lg-8">
              <div className="row mb-3 d-none">
                <div className="col">
                  <div className="card text-white bg-primary shadow">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col">
                          <p className="m-0">Peformance</p>
                          <p className="m-0">
                            <strong>65.2%</strong>
                          </p>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-rocket fa-2x"></i>
                        </div>
                      </div>
                      <p className="text-white-50 small m-0">
                        <i className="fas fa-arrow-up"></i>&nbsp;5% since last
                        month
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card text-white bg-success shadow">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col">
                          <p className="m-0">Peformance</p>
                          <p className="m-0">
                            <strong>65.2%</strong>
                          </p>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-rocket fa-2x"></i>
                        </div>
                      </div>
                      <p className="text-white-50 small m-0">
                        <i className="fas fa-arrow-up"></i>&nbsp;5% since last
                        month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                <div className="card shadow mb-3">
    <div className="card-header py-3">
        <p className="text-primary m-0 fw-bold">shipyard informations</p>
    </div>
    <div className="card-body">
        <form>
            <div className="row">
            <div className="col">
                    <div className="mb-5"><label className="form-label"  htmlFor="username"><strong>shipyard name</strong></label><input className="form-control" type="text" id="username" name="username"  onChange={(e) => setshipyardName(e.target.value)}
              value={shipyardName} /></div>
                </div>
                {/* <div className="col">
                    <div className="mb-3"><label className="form-label" htmlFor="username"><strong>shipyard size</strong></label><input className="form-control" type="text" id="username" name="username" /></div>
                </div> */}
               
            </div>
            <div className="row">
                <div className="col">
                    <div className="mb-3"></div>
                </div>
            </div>
            <div className="mb-3">{/* <button className="btn btn-primary btn-sm" type="submit">Save&nbsp;</button> */}</div>
        </form>
    </div>
</div>
                  <Card2
                    title={"Manpower Cost / Sell"}
                    children={[
                      "Mechanics",
                      "Sales Tracking",
                      "Customer Databases",
                      "Payout Details",
                      "Account setup",
                    ]}
                    stuffData={shipyardStuffData}
                  />
                </div>
              </div>
              <div style={{float:"right"}}>
              <button className="btn btn-primary mt-2"  onClick={handleAddShipyard} type="button">Edit</button>
       </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default EditShipyard;
