import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ProjectDataAdd } from "types";

interface ModalProps{
    title:string;
    options:{name:string,id:number}[];
    subtitle:string
    onClose?:()=>void
    onSubmit:()=>void
    getDataModal:(e:ProjectDataAdd)=>void
    onAddShipyard?:()=>void
    dataModal:any,
    isShow:boolean
}

const Modal=(props:ModalProps)=>{
const [addingData,setAddingData]=useState<ProjectDataAdd>(props.dataModal)
useEffect(()=>{
  console.log("adding",addingData)
},[addingData])
const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { name, value } = event.target;
  setAddingData((prevState:ProjectDataAdd) => ({
    ...prevState,
    [name]: value
  }));
};
const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  const { name, value } = event.target;
  setAddingData((prevState:ProjectDataAdd) => ({
    ...prevState,
    [name]: value
  }));
};
return(
<div className="modal"  role="dialog" style={{ display: 'block' }}>
<div className="modal-dialog my-custom-modal" role="document">
    <div className="modal-content">
        <div className="modal-header">
           {!props.isShow ?<h4 className="modal-title">New {props.title}</h4>:<h4 className="modal-title">Show {props.title}</h4>}<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.onClose}></button>
        </div>

        <div className="modal-body">
        <div className="container">
        <div className="row mb-3">
          {/* First Column */}
          <div className="col-md-6">
            <h6>Project name</h6>
            <input
              className="form-control form-control-user"
              type="text"
              name="name"
              value={addingData.name}
              onChange={handleChange}
              placeholder={`Project name `}
            />
          </div>
          {/* Second Column */}
          <div className="col-md-6">
            <h6>Customer</h6>
            <input
              className="form-control form-control-user"
              type="text"
              value={addingData.customer_name}
              name="customer_name"
              onChange={handleChange}
              placeholder={`Customer name`}
            />
          </div>
        </div>
        <div className="row mb-3">
          {/* First Column */}
          <div className="col-md-6">
            <h6>Project number</h6>
            <input
              className="form-control form-control-user"
              type="number"
              value={addingData.number}
              onChange={handleChange}
              name="number"
              placeholder={`Project number `}
            />
          </div>
          {/* Second Column */}
          <div className="col-md-6">
            <h6>Ship's classification</h6>
            <input
              className="form-control form-control-user"
              type="text"
              value={addingData.classification}
              onChange={handleChange}
              name="classification"
              placeholder={`Ship's classification `}
            />
          </div>
        </div>
        <div className="row mb-3">
          {/* First Column */}
          <div className="col-md-6">
            <h6>budget hours</h6>
            <input
              className="form-control form-control-user"
              type="text"
              onChange={handleChange}
              name="budget_hours"
              value={addingData.budget_hours}
              placeholder={`budget hours`}
            />
          </div>
          {/* Second Column */}
          <div className="col-md-6">
            <h6>Substractors</h6>
            <input
              className="form-control form-control-user"
              type="number"
              onChange={handleChange}
              value={addingData.subcontractors}
              name="subcontractors"
              placeholder={`Substractors `}
            />
          </div>
        </div>
        <div className="row mb-3">
          {/* First Column */}
          <div className="col-md-6">
            <h6>Project cost</h6>
            <input
              className="form-control form-control-user"
              type="number"
              value={addingData.costs}
              name="costs"
              onChange={handleChange}
              placeholder={`Project cost `}
            />
          </div>
          {/* Second Column */}
          <div className="col-md-6">
            <h6>Substractor cost</h6>
            <input
              className="form-control form-control-user"
              type="number"
              name="subcontractors_costs"
              value={addingData.subcontractors_costs}
              onChange={handleChange}
              placeholder={`Substractor cost `}
            />
          </div>
        </div>
        <div className="row mb-3">
          {/* First Column */}
          <div className="col-md-6">
            <h6>Contract invoice</h6>
            <input
              className="form-control form-control-user"
              type="number"
              name="contract_invoice"
              value={addingData.contract_invoice}
              onChange={handleChange}
              placeholder={`Contract invoice `}
            />
          </div>
          {/* Second Column */}
     
          <div className="col-md-6">
           <h6>Shipyard</h6>
            <select name="shipyard_id" value={addingData.shipyard_id} onChange={handleSelectChange} className="btn btn-secondary w-100 " >
                <option value="">Select shipyard</option>
                    {props?.options?.map((option:{name:string,id:number})=><option value={option.id}>{option.name}</option>)}
            </select>
        </div>
        </div>
     
        
        </div>

        <div className="modal-footer d-flex justify-content-between">
          {!props.isShow&& <a href="#" onClick={()=>{props.getDataModal(addingData);setTimeout(()=>{props.onAddShipyard && props.onAddShipyard()},2000)}} >Add new Shipyard</a>}
         <div className="d-flex "><button className="btn btn-light mr-3" type="button" data-bs-dismiss="modal"  onClick={props.onClose}>Close</button>{!props.isShow && <button className="btn btn-primary" onClick={()=>{props.getDataModal(addingData);setTimeout(()=>{ props.onSubmit()},1000)}} type="button">Add</button>}</div></div>
    </div>
</div>
</div>
</div>
)

}
export default Modal