import { listGroups } from "Services/groups";
import ManpowerCard from "../../Components/ManPowerCard/ManPowerCard";
import ProjectCard from "../../Components/ProjectCard/ProjectCard";
import { useEffect, useState } from "react";
import { Group,ProjectDataAdd, Task, Task2 } from "types";
import { useLocation, useNavigate } from "react-router-dom";
import {  addProject } from "Services/projects";
import Header from "Components/Header/Header";
const AddProject = () => {
  const [groups, setGroups] = useState<any>([]);
  const [group, setGroup] = useState<Group>();

  const location = useLocation();
  const [tasks,setTasks]=useState<any>([])
  const data = location.state;
  console.log("dataAddProject", data);
  const navigate=useNavigate()
  const getAllGroup = async () => {
    const groups = await listGroups();
    setGroups(groups.data.data);
  
    };
  useEffect(() => {
    getAllGroup();
  }, []);
  const getAddedTask=(addedTask:any)=>{
    addedTask&& setTasks([...tasks,addedTask])
  }
  const getTasksAfterUpdate=(group:any)=>{
    console.log("getTasksAfterUpdate",group)

    setGroup(group)
    setGroups(groups.map((groupItem:any)=>{
        if(group.title==groupItem.name)
               return group
         else 
          return groupItem
         }))     
        
    }
    useEffect(()=>{
      console.log("groupesssss",groups)
    },[groups,group])
  
  const handleAddProject=async()=>{
   let projectData={...data,
   tasks: tasks}
    console.log("addData",projectData)
    const addProjectRes=await addProject(projectData)
    navigate("/projects")
  }
  useEffect(()=>{
    console.log("tasks",tasks)
  },[tasks])
  
  return (
    <div className="container-fluid">
          <Header />
      <div className="row">
        {/* <div className="col-md-2 ps-0">
          <Menu
            title="Slim"
            items={[
              { name: "Dashboard" },
              { name: "Projects" },
              { name: "Shipyards" },
              { name: "Profile" },
              { name: "Register" },
            ]}
          />
          </div>*/}
        {/*<div className="col-md-10">*/}
        <div className="col-md-12">
          <h3 className="text-dark mb-4">Add new project</h3>
          <div className="row mb-3">
            {groups?.map((group: Group, index:number) => (
              <div key={index} className="col-lg-4">
                <div className="card mb-3"></div>
                <ProjectCard getTasksAfterUpdate={getTasksAfterUpdate} getAddedTask={getAddedTask} title={group.name} projectData={data} tasks={group.tasks} />
              </div>
            ))}
          </div>
          <div style={{float:"right"}}>
              <button className="btn btn-primary" onClick={handleAddProject} type="button">Submit</button>
       </div>
        </div>
      </div>
    </div>
  );
};

export default AddProject;
