import { listGroups } from "Services/groups";
import { listStaffs } from "Services/staffs";
import { listLogistics } from "Services/logistics";
import { FormEvent, useEffect, useState } from "react";
import { Group, Task, Task2, TaskData } from "types";
import { getShipyardById } from "Services/shipyards";
interface ModalTaskProps{
   onSubmit:(e:FormEvent<HTMLButtonElement>)=>void
   task:Task
   onCancel:()=>void
   getAddedTaskData:(e:any)=>void,
   isEdit:boolean;
   article?:Task2;
   projectData:any;
}
const ModalTask = (props:ModalTaskProps) => {
  console.log("propsTask",props)
  const [groups,setGroups]=useState<Group[]>([])
  const [staffs,setStaffs]=useState<any>([])
  const [logistics,setLogistics]=useState<any>([])
  const [taskDetails, setTaskDetails] = useState<TaskData>(
    {
    group: "",
    subgroup: props.task.name,
    itemNumber: '',
    task: '',
    from: '',
    to: '',
    mechanics: "",
    fitters: "2",
    welders: "",
    firefighters: "",
    riggers: "",
    staging: false,
    forklift: false,
    truck: false,
    manitou: false,
    crane: false,
  });


  const [taskDetailsData, setTaskDetailsData] = useState<Task2>({
    number:"",
    details:"",
    start_date:"",
    end_date :"",
    taskname_id :`${props.task.id}`,
    staffs:[],
    logistics:[]
  });

  useEffect(()=>{
    props.isEdit&& props.article && setTaskDetailsData(props.article)
  },[props.isEdit])
  const getAllGroups=async()=>{
    const groupsData=await listGroups();
    console.log("groups",groupsData);
    setGroups(groupsData.data.data)
    setTaskDetails({...taskDetails,group:groupsData.data.data.filter((group:any)=>group.id===parseInt(props.task.group_id))[0]?.name})
  }
  const getAllStaffs=async()=>{
    const staffsData=await getShipyardById(props.projectData.shipyard_id);
    console.log("staffs",staffsData);
    const transformedArray = staffsData.data.shipyard_staff.map((entry:any) => ({
      staff_id: entry.staff_id,
      shipyard_id: entry.shipyard_id,
      number: entry.number,
      cost: entry.cost,
      sell: entry.sell,
      name: entry.staff.name,
    }));
    setStaffs(transformedArray)
    console.log("transformed",transformedArray)
  }
  const getAllLogistics=async()=>{
    const logisticsData=await listLogistics();
    console.log("logistics",logisticsData);
    setLogistics(logisticsData.data.data)
  }
  useEffect(()=>{
     getAllGroups()
     getAllStaffs()
     getAllLogistics()
  },[])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | HTMLInputElement>) => {

      const { name, value, type, checked }:any = e.target;
      setTaskDetailsData(prevState => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value
      }));

    };

    useEffect(()=>{
      console.log("taskdetailes",taskDetailsData)
    },[taskDetailsData])
    function keyExistsInArray(array:any, key: string): boolean {
      for (const obj of array) {
          if (key in obj) {
              return true;
          }
      }
      return false;
  }
   const handleChangeStaffs=(e:any,index:number)=>{
      const staffObject=staffs.filter((staff:any)=>staff.name==e.target.name)[0] 
      staffObject.value=e.target.value
      console.log("staff",staffObject)
      const newData:any = { ...taskDetailsData};
      console.log("newData",newData)
      if(taskDetailsData.staffs.length==0)
      setTaskDetailsData((prevState:any) => {
       return({ ...prevState,
        staffs: [
          ...prevState.staffs,
          { ...staffObject }
        ]
      })})
      else {
        
        const updatedObject=newData.staffs.filter((staff:any)=>(
          staff.name==e.target.name
        ))[0]
        if(!updatedObject){
          const staffObjectUpdate=staffs.filter((staff:any)=>staff.name==e.target.name)[0] 
      staffObjectUpdate.value=e.target.value
          setTaskDetailsData((prevState:any) => {
            return({ ...prevState,
             staffs: [
               ...prevState.staffs,
               { ...staffObjectUpdate }
             ]
           })})
        }

        else{
        updatedObject.value=e.target.value;
        newData.staffs[index]=updatedObject;
        console.log("newData",newData)
        setTaskDetailsData(newData)

      }
    }
      
          

        
        
      
 
    }
    const handleChangeLogistics=(e:any,index:number)=>{
      const staffObject=logistics.filter((logistic:any)=>logistic.name==e.target.name)[0] 
      staffObject.value=e.target.checked
      console.log("staff",staffObject)
      const newData:any = { ...taskDetailsData};
      console.log("newData",newData)
      if(taskDetailsData.logistics.length==0)
      setTaskDetailsData((prevState:any) => {
       return({ ...prevState,
        logistics: [
          ...prevState.logistics,
          { ...staffObject }
        ]
      })})
      else {
        
        const updatedObject=newData.logistics.filter((staff:any)=>(
          staff.name==e.target.name
        ))[0]
        if(!updatedObject){
          const staffObjectUpdate=logistics.filter((logistic:any)=>logistic.name==e.target.name)[0] 
      staffObjectUpdate.value=e.target.checked
          setTaskDetailsData((prevState:any) => {
            return({ ...prevState,
             logistics: [
               ...prevState.logistics,
               { ...staffObjectUpdate }
             ]
           })})
        }

        else{
        updatedObject.value=e.target.checked;
        newData.logistics[index]=updatedObject;
        console.log("newData",newData)
        setTaskDetailsData(newData)

      }
    }
      
          

        
        
      
 
    }
     
     
    










    return (
    <div className="modal"  role="dialog" style={{ display: 'block' }}>
<div className="modal-dialog modal-lg my-custom-modal" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="mb-3"></div>
              <div className="row">
                <div className="col">
                  <div className="mb-3"><label className="form-label" htmlFor="group"><strong>Group :</strong></label><input type="text" id="group" name="group" disabled value={taskDetails.group}  /></div>
                </div>
                <div className="col">
                  <div className="mb-3"><label className="form-label" htmlFor="subgroup"><strong>Subgroup :</strong></label><input type="text" disabled id="subgroup" name="subgroup" value={taskDetails.subgroup}  /></div>
                </div>
                <div className="col">
                  <div className="mb-3"><label className="form-label" htmlFor="number"><strong>n° ITEM</strong></label><input type="number" id="number" name="number" value={taskDetailsData?.number} onChange={handleChange} /></div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3"><label className="form-label" htmlFor="details"><strong>TASK :</strong></label></div><textarea className="form-control-lg" style={{  width: '100%' }} value={taskDetailsData?.details} onChange={handleChange} name="details"></textarea>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3"><label className="form-label" htmlFor="from"><strong>From :&nbsp;</strong></label><input className="btn" type="date" value={taskDetailsData?.start_date} onChange={handleChange} name="start_date" /></div>
                </div>
                <div className="col">
                  <div className="mb-3"><label className="form-label" htmlFor="to"><strong>To :&nbsp;</strong></label><input className="btn" type="date" value={taskDetailsData?.end_date} onChange={handleChange} name="end_date" /></div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div></div>
                  <h4 className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px' }}></h4><strong style={{ marginRight: '103px', paddingBottom: '0px', marginBottom: '23px' }}><span style={{ color: 'rgb(15, 15, 16)' }}>Manpower needs :</span><br /></strong>
                  {!props.isEdit ?staffs?.map((staff: any, index:number) => (
                    <h4 key={index} className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px', marginTop: '14px' }}>
                      <input
                        type="number"
                        style={{ float: 'right', width: '45px' }}
                        value={taskDetailsData?.staffs[index]?.value}
                        onChange={(e:any)=>handleChangeStaffs(e,index)}
                        name={staffs[index]?.name}
                      />
                      {`${staff.name}(limit:${staff.number})`}<br/>
                      {taskDetailsData?.staffs[index]?.value>staff.number &&<span style={{color:"red"}}>{`${Number(taskDetailsData?.staffs[index]?.value)-staff.number} ${staff.name} depassed`} </span>}
                    </h4>
                  )):taskDetailsData.staffs?.map((staff: { name: string }, index:number) => (
                    <h4 key={index} className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px', marginTop: '14px' }}>
                      <input
                        type="number"
                        style={{ float: 'right', width: '45px' }}
                        value={taskDetailsData?.staffs[index]?.value}
                        onChange={(e:any)=>handleChangeStaffs(e,index)}
                        name={staffs[index]?.name}
                      />
                      {staff.name}
                    </h4>
                  ))}

                 </div>
                <div className="col">
                  <div></div>
                  <h4 className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px' }}></h4><strong style={{ marginRight: '103px', paddingBottom: '0px', marginBottom: '23px' }}><span style={{ color: 'rgb(10, 10, 10)' }}>Logistic needs :</span></strong>
                  {!props.isEdit ?logistics?.map((logistic: { name: string }, index:number) => (
                    <h4 key={index} className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px', marginTop: '14px' }}>
                      <input
                        type="checkbox"
                        style={{ float: 'right', width: '45px' }}
                        value={taskDetailsData?.logistics[index]?.value}
                        onChange={(e:any)=>handleChangeLogistics(e,index)}
                        name={logistics[index]?.name}
                      />
                      {logistic.name}
                    </h4>
                  )):taskDetailsData.logistics?.map((logistic: { name: string }, index:number) => (
                    <h4 key={index} className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px', marginTop: '14px' }}>
                      <input
                        type="checkbox"
                        style={{ float: 'right', width: '45px' }}
                        value={taskDetailsData?.logistics[index]?.value}
                        checked={Boolean(taskDetailsData?.logistics[index]?.value)}
                        onChange={(e:any)=>handleChangeLogistics(e,index)}
                        name={logistics[index]?.name}
                      />
                      {logistic.name}
                    </h4>
                  ))} </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-light" onClick={props.onCancel} type="button" data-bs-dismiss="modal">Close</button>
              <button className="btn btn-primary" onClick={(e)=>{props.getAddedTaskData(taskDetailsData);props.onSubmit(e)}} type="button">Save</button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default ModalTask;
