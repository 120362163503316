import DataTable from "../../Components/DataTable/DataTable";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import NavBar from "../../Components/NavBar/NavBar";
import Menu from "../../Components/Menu/Menu";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDashboard, faProjectDiagram, faShip, faSignIn, faUser } from "@fortawesome/free-solid-svg-icons";
import { listProjects,deleteProject } from "Services/projects";
import { Project } from "types";

function Projects() {
  const [projects,setProjects]=useState<Project[]>()

  const [searchTerm, setSearchTerm] = useState("");
  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedpaginationItem, setSelectedpaginationItem] = useState<number>(10);

  const [formTable, setFrom] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);


  const getAllProjects=async(data = {})=>{
    const projects=await listProjects(data);
    setProjects(projects.data.data)
    setLastPage(projects.data.last_page);
    setCurrentPage(projects.data.current_page);
    setSelectedpaginationItem(projects.data.per_page);
  }
  const onPaginate = async (page: number) => {
    try {
      let data = { page: page };
      getAllProjects(data  );
    } catch (error) {
      console.error("Error in DeleteData:", error);
    }
  };
  const onChangePerPage = async (page: number) => {
    try {
      let data = { perpage: page };
      getAllProjects(data);
    } catch (error) {
      console.error("Error in DeleteData:", error);
    }
  };
  const DeleteData = async (id:  number) => {
    try {
      const req = await deleteProject(id);
      getAllProjects();
      console.log("Request result:", req);
    } catch (error) {
      console.error("Error in DeleteData:", error);
    }
    //alert(id);
    
  };
  const SearchData = async(searchText:  string) => {
 
    console.log("re999",)
  };
  useEffect(()=>{
    getAllProjects()
  },[])
  return (
    <React.Fragment >
       <Header />
        <div className="container-fluid">
          <div className="row">
          {/*<div className="col-md-2 ps-0">

      <Menu title="Slim" items={[{name:"Dashboard",icon:<FontAwesomeIcon icon={faDashboard} />},{name:"Projects",icon:<FontAwesomeIcon icon={faProjectDiagram}/>},{name:"Shipyards",icon:<FontAwesomeIcon icon={faShip}/>},{name:"Profile",icon:<FontAwesomeIcon icon={faUser} />},{name:"Register",icon:<FontAwesomeIcon icon={faSignIn} />}]}/>
     </div>*/}
      {/*<div className="col-md-10">*/} 
      <div className="col-md-12">
    <div className="d-flex flex-column" id="content-wrapper">
        <div id="content">
           
            <div className="container-fluid">
                <NavBar title="Projects" />
       
                <DataTable  lastPage={lastPage} currentPage={currentPage} from={formTable} to={to} total={total}  onPaginate={onPaginate} onChangePerPage={onChangePerPage}     onSearch={SearchData} onDeleteData={DeleteData}  paginationItems={[10,15,20,30]} selectedpaginationItem={selectedpaginationItem} title="Projects" headers={Object.keys({
                    Project_name:"",
                    Customer:"",
                    Project_number:"",
                    Project_start:"",
                    Delivery_date:"",
                    Actions:""
                })} data={projects} />
            </div>
        </div>
          <Footer title="Copyright © Brand 2024" />
    </div><a className="border rounded d-inline scroll-to-top" href="#page-top"><i className="fas fa-angle-up"></i></a>
</div>
</div>
</div>
</React.Fragment>
  )

}

export default Projects;
