import { useEffect, useState, ChangeEvent } from "react";
import Modal from "../Modal/Modal";
import Button from 'Components/Button/Button';
import { listStaffs } from "Services/staffs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

interface PropsCard {
    title?: string;
    data?: Array<{
        id: number;
        staff_id?: number | undefined;
        name: string;
        number?: number | undefined;
        cost: number;
        sell: number;
    }>; className?: string;
    children: React.ReactNode[];
    onStuffDataChange: (newStuffData: Array<{ id: number; staff_id: number | undefined; name: string; cost: number; sell: number }>) => void;

}

const Card = (props: PropsCard) => {
    const [errorSelectManpowerMsg, setErrorSelectManpowerMsg] = useState<string>('');
    const [errorNumberManpowerMsg, setErrorNumberManpowerMsg] = useState<string>('');

    const [showModalAdd, setShowModalAdd] = useState<boolean>(false);
    const [selectedItemType, setSelectedItemType] = useState('existing');
    const [cost, setCost] = useState(0);
    const [sell, setSell] = useState(0);
    const [selectedItemId, setSelectedItemId] = useState<number | undefined>(undefined);
    const [selectedStaffName, setSelectedStaffName] = useState<string>("");
    const [numberManpower, setNumberManpower] = useState<number | undefined>(1); // Set the default value to 1

    const [amountGained, setAmountGained] = useState(0);
    const [staffs, setStaffs] = useState([])
    const [stuffData, setStuffData] = useState<Array<{ id: number; staff_id: number | undefined; name: string; number: number | undefined; cost: number; sell: number }>>([]);
    const [nextItemId, setNextItemId] = useState(1);
    const [editItemId, setEditItemId] = useState<number | null>(null);
    useEffect(() => {
        // Check if data prop is provided and not empty
        if (props.data && props.data.length > 0) {
            // Assuming you want to update stuffData when data prop changes
            const updatedStuffData = props.data.map((item) => ({
                ...item,
                staff_id: item.staff_id || undefined, // Ensure staff_id is defined
                number: item.number || undefined, // Ensure number is defined
            }));

            setStuffData(updatedStuffData);
            setNextItemId(props.data.length + 1)
        }
    }, [props.data]);

    const handleAddSubmit = () => {

        // Validate if Select Manpower is chosen
        if (!selectedItemId && !selectedStaffName) {
            // Set an error message to be displayed
            setErrorSelectManpowerMsg("Select Manpower is required.");
            return;
        }
        if (typeof numberManpower !== 'number' || isNaN(numberManpower)) {
            // Set an error message to be displayed
            setErrorNumberManpowerMsg("Number Manpower is required.");
            return;
        }

        // Clear any previous error messages
        setErrorNumberManpowerMsg('');
        // Clear any previous error messages
        setErrorSelectManpowerMsg('');
        if (editItemId !== null) {
            console.log("hethi1", numberManpower);
            // If editing an existing item, update the stuff array
            const updatedStuff = stuffData.map(item =>
                item.id === editItemId ? { ...item, staff_id: selectedItemId, name: selectedStaffName, number: numberManpower, cost, sell } : item
            );
            console.log("hethi", updatedStuff);
            setStuffData(updatedStuff);
            setEditItemId(null);
            props.onStuffDataChange(updatedStuff);  // Update onStuffDataChange with the corrected type

        } else {
            const newItem = {
                id: nextItemId,
                staff_id: selectedItemId,
                name: selectedStaffName,
                number: numberManpower,
                cost,
                sell,
            };

            setStuffData([...stuffData, newItem]);
            setNextItemId(nextItemId + 1);
            setShowModalAdd(false);
            props.onStuffDataChange([...stuffData, newItem]);
        }

        setShowModalAdd(false);
        // Additional logic for submitting the data or closing the modal
    };
    const handleEdit = (itemId: number) => {
        const itemToEdit = stuffData.find(item => item.id === itemId);
        if (itemToEdit) {
            setEditItemId(itemId);
            setSelectedItemType('existing'); // Assume you want to edit existing items
            setCost(itemToEdit.cost);
            setSell(itemToEdit.sell);
            setNumberManpower(itemToEdit.number);
            setShowModalAdd(true);
        }
    };
    const handleDelete = (itemId: number) => {
        const updatedStuff = stuffData.filter(item => item.id !== itemId);
        setStuffData(updatedStuff);
        props.onStuffDataChange(updatedStuff);
    };
    const getAllStaffs = async () => {
        const staffsData = await listStaffs();
        console.log("staffs", staffsData);
        setStaffs(staffsData.data.data)
    }
    useEffect(() => {

        getAllStaffs()

    }, [])
    const handleCostChange = (event: ChangeEvent<HTMLInputElement>) => {
        const costValue = parseFloat(event.target.value);
        setCost(isNaN(costValue) ? 0 : costValue);
        updateAmountGained(isNaN(costValue) ? 0 : costValue, sell);
    };

    const handleSellChange = (event: ChangeEvent<HTMLInputElement>) => {
        const sellValue = parseFloat(event.target.value);
        setSell(isNaN(sellValue) ? 0 : sellValue);
        updateAmountGained(cost, isNaN(sellValue) ? 0 : sellValue);
    };

    const updateAmountGained = (newCost: number, newSell: number) => {
        const calculatedAmountGained = newSell - newCost;
        setAmountGained(isNaN(calculatedAmountGained) ? 0 : calculatedAmountGained);
    };
    const ShowModalAddFun = () => {
        setShowModalAdd(!showModalAdd);
        setSelectedItemType('existing');
        setNumberManpower(1);
        setCost(0);
        setSell(0);
        setSelectedItemId(0);
        setSelectedStaffName("");
        setErrorNumberManpowerMsg('');
        setErrorSelectManpowerMsg('');
    };
    const handleModalClickOutside = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if ((event.target as HTMLDivElement).classList.contains('modal')) {
            // Check if the clicked element has the 'modal' class
            setShowModalAdd(false); // Close the modal
        }
    };
    
    return (

        <div className={`card ${props.className || 'shadow mb-4'}`}>

            {props.title && (
                <div className="card-header py-3">
                    <a href='#' onClick={ShowModalAddFun} style={{ float: "right" }}>+ Add New</a>
                    <h6 className="text-primary fw-bold m-0">{props.title}</h6>
                </div>
            )}

            <div className="card-body">
                {stuffData && stuffData.length > 0 ? (
                    stuffData.map((item, index) => (
                        <h4 key={index} className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px' }}>
                            <span className="badge bg-primary" style={{ float: 'right', width: '45px', margin: "auto" }} >{item.number}</span>
                            {item.name}
                            <FontAwesomeIcon onClick={() => handleDelete(item.id)} style={{ marginLeft: '10px', cursor: "pointer" }} icon={faTrash} />
                            <FontAwesomeIcon onClick={() => handleEdit(item.id)} style={{ marginLeft: '10px', cursor: "pointer" }} icon={faEdit} />
                        </h4>
                    ))
                ) : (
                    <p>No items found.</p>
                )}

            </div>
            {showModalAdd && (
                <div>
                     <div className="modal-overlay" onClick={() => setShowModalAdd(false)}></div>
                
                <div className="modal" role="dialog" style={{ display: 'block' }} onClick={(e) => handleModalClickOutside(e)}>
                    <div className="modal-dialog my-custom-modal" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{editItemId !== null ? 'Edit' : 'Add'} Stuff</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModalAdd(false)}></button>
                            </div>

                            <div className="modal-body">
                                <div className="container">
                                    <div className="row mb-3">
                                        <h6>Select Manpower Type:</h6>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="itemType"
                                                id="existingItem"
                                                value="existing"
                                                checked={selectedItemType === 'existing'}
                                                onChange={() => setSelectedItemType('existing')}
                                            />
                                            <label className="form-check-label" htmlFor="existingItem">Existing Manpower</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="itemType"
                                                id="newItem"
                                                value="new"
                                                checked={selectedItemType === 'new'}
                                                onChange={() => setSelectedItemType('new')}
                                            />
                                            <label className="form-check-label" htmlFor="newItem">New Manpower</label>
                                        </div>
                                    </div>



                                    {selectedItemType === 'existing' ? (
                                        <div className="row mb-3">
                                            <h6 className="form-label">Select Manpower:</h6>
                                            <select
                                                className="form-select"
                                                id="selectItem"
                                                onChange={(e) => {
                                                    setSelectedItemId(parseInt(e.target.value, 10) || undefined);
                                                    setSelectedStaffName(e.target.options[e.target.selectedIndex].text);
                                                    setErrorSelectManpowerMsg('');
                                                }}
                                                value={selectedItemId}
                                            >
                                                <option value={0} disabled selected>Select Manpower</option>
                                                {staffs?.map((staff: { name: string, id: number }, index) => (
                                                    <option key={index} value={staff.id}>{staff.name}</option>
                                                ))}
                                            </select>
                                            {errorSelectManpowerMsg && <p style={{ color: 'red', marginBottom: 0 }}>{errorSelectManpowerMsg}</p>}
                                        </div>
                                    ) : (
                                        <div className="row mb-3">
                                            <h6 className="form-label">New Manpower Name:</h6>
                                            <input type="text" value={selectedStaffName}
                                                onChange={(e) => {
                                                    setSelectedStaffName(e.target.value);
                                                    setErrorSelectManpowerMsg('');
                                                    setSelectedItemId(0);
                                                }} className="form-control" id="newItemName" placeholder="Enter new manpower name" />
                                                                                            {errorSelectManpowerMsg && <p style={{ color: 'red', marginBottom: 0 }}>{errorSelectManpowerMsg}</p>}

                                        </div>
                                    )}
                                    <div className="row mb-3">
                                        <h6 className="form-label">Number :</h6>
                                        <input type="number" className="form-control" id="number" min={1} placeholder="Enter Number" onChange={(e) => { setErrorNumberManpowerMsg(''); setNumberManpower(parseInt(e.target.value, 10) || undefined); }} // Parse the value as a number
                                            value={numberManpower || ''} />{errorNumberManpowerMsg && <p style={{ color: 'red', marginBottom: 0 }}>{errorNumberManpowerMsg}</p>}
                                    </div>
                                    <div className="row mb-3">
                                        <h6 className="form-label">Cost :</h6>
                                        <input type="number" className="form-control" value={cost} id="cost" placeholder="Enter Cost" onChange={handleCostChange} />
                                    </div>

                                    <div className="row mb-3">
                                        <h6 className="form-label">Sell:</h6>
                                        <input type="number" className="form-control" value={sell} id="sell" placeholder="Enter Sell" onChange={handleSellChange} />
                                    </div>

                                    <div className="row mb-3">
                                        {/* Display total cost dynamically */}
                                        <p>Amount gained: ${amountGained.toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button className="btn btn-light" type="button" data-bs-dismiss="modal" onClick={() => setShowModalAdd(false)}>Close</button>
                                <button className="btn btn-primary" onClick={handleAddSubmit} type="button">{editItemId !== null ? 'Edit' : 'Add'}</button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            )}
        </div>
    );
};

export default Card;
