import axios from "axios";
import {Shipyard} from "../types"
export const listShipyards = (data?: any) => {
    try {
        let Param = '';

        if (data?.page) {
            Param += `page=${data.page}`;
        }
        
        if (data?.search) {
            Param += `${Param ? '&' : ''}search=${data.search}`;
        }
        if (data?.perpage) {
            Param += `${Param ? '&' : ''}perPage=${data.perpage}`;
        }
             
      return axios.get(`https://testi.sfaxcharityerpapp.com/api/shipyards?${Param}`);
    } catch (error) {
      console.error("Error in listShipyards:", error);
      throw error; 
    }
  };
  
export const addPShipyard=(formData: FormData)=>{
    return axios.post(`https://testi.sfaxcharityerpapp.com/api/shipyards`,formData)
}
export const updateShipyard=(id:number,formData:FormData)=>{
    return axios.post(`https://testi.sfaxcharityerpapp.com/api/shipyards/${id}`,formData)
}
export const deleteShipyard=(id:number)=>{
    return axios.delete(`https://testi.sfaxcharityerpapp.com/api/shipyards/${id}`)
}
export const getShipyardById=(id:number)=>{
    return axios.get(`https://testi.sfaxcharityerpapp.com/api/shipyards/${id}`)
}
