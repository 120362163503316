import React from "react";

interface CardProps{
    title:string;
    children:any;
    className?:string
    stuffData: Array<{ id: number; name: string; cost: number; sell: number }>;

}
const Card2=(cardProps:CardProps)=>{
    return( 
   
<div className="card shadow">
    <div className="card-header py-3">
        <p className="text-primary m-0 fw-bold">{cardProps.title}</p>
    </div>
    <div className="card-body">
    {cardProps.stuffData.length > 0 ? (
    cardProps.stuffData.map((item, index) => (
        <h4 key={index} className="small fw-bold" style={{ paddingBottom: "0px", marginBottom: "19px" }}>
            <span className="badge bg-primary" style={{ float: "right", width: "71px", marginLeft: 5 }}>Sell: {item.sell}</span>
            <span className="badge bg-primary" style={{ float: "right", width: "71px" }}>Cost: {item.cost}</span>
            {item.name}
        </h4>
    ))
) : (
    <p>No items found.</p>
)}

    </div>
</div>
 )
}
export default Card2;