import React, { useState, ChangeEvent,useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from "../../Components/Card/Card";
import Card2 from "../../Components/Card/Card2";
import Card3 from "../../Components/Card/Card3";
import Menu from "../../Components/Menu/Menu";
import Header from "../../Components/Header/Header";
import { addPShipyard } from "Services/shipyards";
import { useLocation,useNavigate } from 'react-router-dom';
const AddShipyard = () => {
  const [shipyardStuffData, setShipyardStuffData] = useState<Array<{ id: number; staff_id: number | undefined; name: string; cost: number; sell: number }>>([]);
  const location = useLocation();
  const data = location.state;
console.log(data);
  const handleStuffDataChange = (newStuffData: Array<{ id: number; staff_id: number | undefined; name: string; cost: number; sell: number }>) => {
    setShipyardStuffData(newStuffData);
  };
  const [shipyardName, setshipyardName] = useState('');
  const navigate = useNavigate();
  const [errorShipyardNameMsg, setErrorShipyardNameMsg] = useState<string>('');

  const handleAddShipyard = async () => {

    if (!shipyardName) {
      // Set an error message to be displayed
      setErrorShipyardNameMsg("Shipyard name is required.");
      return;
    }
    const formData = new FormData();
    formData.append('name', shipyardName);
    formData.append('photo', imageSrc as File);
    formData.append('staffs', JSON.stringify(shipyardStuffData));

    try {
      const addShipyardRes = await addPShipyard(formData);

      // Check if the status code is either 200 or 201
      if (addShipyardRes.status === 200 || addShipyardRes.status === 201) {
        // Display success toast
        toast.success('Shipyard added successfully!', {
          position: 'top-center', // Corrected position string
          autoClose: 2000, // Adjust the duration of the toast
        });
        console.log("data",data)
        if(data){
          //alert();
          navigate("/projects", { state: data });
        }else{
        setTimeout(() => {
          navigate('/shipyards');
        }, 2000);
        // Redirect to /shipyard after a brief delay
      }
      }
    } catch (error) {
      console.error("Error adding shipyard:", error);
    }
  };


  const [imageSrc, setImageSrc] = useState<File | string>('/not_found.jpg');

  const handlePhotoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      setImageSrc(selectedFile);
    }
  };
  return (
    <>
      <Header />
      <ToastContainer />
      <div className="container-fluid ">
        <div className="row" >

          <div className="col-md-12">
            <h3 className="text-dark mb-4">Shipyard </h3>
            <div className="row mb-3">
              <div className="col-lg-4">
                <div className="card mb-3">
                  <div className="card-body text-center shadow">
                    <img
                      className="rounded-circle mb-3 mt-4"
                      src={imageSrc instanceof File ? URL.createObjectURL(imageSrc) : imageSrc}
                      alt="User's profile"
                      width="121"
                      height="117"
                    />
                    <div className="mb-3">
                      <input
                        type="file"
                        accept="image/*"
                        id="photoInput"
                        style={{ display: 'none' }}
                        onChange={handlePhotoChange}
                      />
                      <label htmlFor="photoInput" className="btn btn-primary btn-sm">
                        Change Photo
                      </label>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-8">

                <div className="row">
                  <div className="col">
                    <div className="card shadow mb-3">
                      <div className="card-header py-3">
                        <p className="text-primary m-0 fw-bold">shipyard informations</p>
                      </div>
                      <div className="card-body">
                        <form>
                          <div className="row">
                            <div className="col">
                              <div className="mb-5"><label className="form-label" htmlFor="username"><strong>shipyard name</strong></label><input className="form-control" type="text" id="username" name="username" onChange={(e) => { setshipyardName(e.target.value); setErrorShipyardNameMsg('') }}
                                value={shipyardName} />{errorShipyardNameMsg && <p style={{ color: 'red', marginBottom: 0 }}>{errorShipyardNameMsg}</p>}
                              </div>
                            </div>
                            <div className="col">
                              <div className="mb-3">{/* <label className="form-label" htmlFor="username"><strong>shipyard size</strong></label><input className="form-control" type="text" id="username" name="username" /> */}</div>
                            </div>

                          </div>
                          <div className="row">
                            <div className="col">
                              <div className="mb-3"></div>
                            </div>
                          </div>
                          <div className="mb-3">{/* <button className="btn btn-primary btn-sm" type="submit">Save&nbsp;</button> */}</div>
                        </form>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
              <div className="col-lg-4">
                <Card
                  title="Stuff"
                  children={[
                    "Mechanics",
                    "Sales Tracking",
                    "Customer Databases",
                    "Payout Details",
                    "Account setup",
                  ]}
                  onStuffDataChange={handleStuffDataChange}
                />

              </div>
              <div className="col-lg-8">
                <Card2
                  title={"Manpower Cost / Sell"}
                  children={[
                    "Mechanics",
                    "Sales Tracking",
                    "Customer Databases",
                    "Payout Details",
                    "Account setup",
                  ]}
                  stuffData={shipyardStuffData}
                />

              </div>
              <div className="col-lg-12">
                <div style={{ float: "right" }}>
                  <button className="btn btn-primary mt-2" onClick={handleAddShipyard} type="button">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddShipyard;
