import axios from "axios";
import {Group} from "../types"
export const listGroups=()=>{
    return axios.get(`https://testi.sfaxcharityerpapp.com/api/groups`)
}
export const addGroup=(group:Group)=>{
    return axios.post(`https://testi.sfaxcharityerpapp.com/api/groups`,group)

}
export const updateGroup=(id:number,group:Group)=>{
    return axios.put(`https://testi.sfaxcharityerpapp.com/api/groups/${id}`,group)

}
export const deleteGroup=(id:number)=>{
    return axios.delete(`https://testi.sfaxcharityerpapp.com/api/groups/${id}`)

}
export const getGroupById=(id:number)=>{
    return axios.get(`https://testi.sfaxcharityerpapp.com/api/groups/${id}`)
}

