import axios from "axios";
import {Project, ProjetAddData} from "../types"

export const listProjects = (data?: any) => {
    try {
        let Param = '';

        if (data?.page) {
            Param += `page=${data.page}`;
        }
        
        if (data?.search) {
            Param += `${Param ? '&' : ''}search=${data.search}`;
        }
        if (data?.perpage) {
            Param += `${Param ? '&' : ''}perPage=${data.perpage}`;
        }
             
      return axios.get(`https://testi.sfaxcharityerpapp.com/api/projects?${Param}`);
    } catch (error) {
      console.error("Error in projects:", error);
      throw error; 
    }
  };
  
export const addProject=(project:ProjetAddData)=>{
    console.log("projectApi",project)
    return axios.post(`https://testi.sfaxcharityerpapp.com/api/projects`,project)

}
export const updateProject=(id:number,project:Project)=>{
    return axios.put(`https://testi.sfaxcharityerpapp.com/api/projects/${id}`,project)

}
export const deleteProject=(id:number)=>{
    return axios.delete(`https://testi.sfaxcharityerpapp.com/api/projects/${id}`)

}
export const getProjectById=(id:number)=>{
    return axios.get(`https://testi.sfaxcharityerpapp.com/api/projects/${id}`)
}

