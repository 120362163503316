import Button from "../Button/Button";
import { useEffect, useState,useRef } from "react";
import Modal from "../Modal/Modal";
import { listShipyards } from "Services/shipyards";
import { useLocation,useNavigate } from "react-router-dom";
interface NavBarProps {
  title: string;
}
const NavBar = (props: NavBarProps) => {
  const navigate=useNavigate()
  const location = useLocation();
  let data = location.state;
 
  useEffect(()=>{
  
    console.log('return',data);
    if(data){
      setShowModalAdd(true);
    }

  },[data])
  const [addingDataModal,setAddingDataModal]=useState<any>()
  const addingDataModalRef = useRef();
  const [showModalAdd, setShowModalAdd] = useState<boolean>(false);
  const [shipyards,setShipyards]=useState<any>([])
  const getAllShipyard=async()=>{
    const shipyardata:any=await listShipyards()
    console.log("shipyards",shipyardata)
    setShipyards(shipyardata.data)
  }
  const getDataModal=(addingData:any)=>{
    console.log("addingDataModal",addingData)
    addingDataModalRef.current = addingData;
       setAddingDataModal((prevstate:any)=>addingData)
  }
  const handleButtonClick = () => {
 
    if (props.title.toLowerCase() === "shipyards") {
      navigate("/shipyards/new-shipyard");
    } else {
    
      setShowModalAdd(!showModalAdd);
    }
  };
  useEffect(()=>{
     console.log("addingUser",addingDataModal)
  },[addingDataModal])
  useEffect(()=>{
    getAllShipyard()
  },[showModalAdd])
  return (
    <div>
      <Button
        text={`+ Add New ${props.title.slice(0, -1)}`}
        className="btn btn-primary"
        onClick={handleButtonClick} 
      />
      <h3 className="text-dark d-flex mb-4">{props.title}</h3>
      {showModalAdd && (
        <Modal
        isShow={false}
        dataModal={data ? data : []}
          getDataModal={getDataModal}
          onClose={() => {setShowModalAdd(false) ;}}
          onSubmit={async () => {
            console.log(addingDataModalRef.current); 
            navigate("/projects/new-project", { state: addingDataModalRef.current });
          }}
          onAddShipyard={async () => {
            console.log(addingDataModalRef.current); 
            navigate("/shipyards/new-shipyard", { state: addingDataModalRef.current });
          }}
           title="Project"
          subtitle="shipyard"
          options={shipyards.data}
        />
      )}
    </div>
  );
};
export default NavBar;
